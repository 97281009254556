/* 1. Components */
@use 'components/example-viewer';
@use 'components/input';

/* 2. Overrides */
@use 'overrides/angular-material';
@use 'overrides/highlightjs';
@use 'overrides/perfect-scrollbar';
@use 'overrides/quill';

markdown {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.25;
    }

    p {
        margin-bottom: 0.5rem;
    }

    ul {
        margin-bottom: 1rem;
        padding-left: 1.5rem;
        list-style-type: disc;
    }

    ol {
        margin: 0;
        padding-left: 1.5rem;
        list-style-type: decimal;
    }

    li {
        margin-bottom: 0.5rem;
        line-height: 1.5;
    }
}