/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "~@ng-select/ng-select/themes/material.theme.css";

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

// Angular CDK Menu

.cdk-menu {
    display: inline-flex;
    flex-direction: column;
    min-width: 180px;
    max-width: 280px;
    background-color: rgba(255, 255, 255);
    padding: 6px 0;
}

.cdk-menu-item {
    background-color: #00000011;
    cursor: pointer;
    border: none;

    user-select: none;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
}

.cdk-menu-item:hover {
    background-color: rgb(208, 208, 208);
}

.cdk-menu-item:active {
    background-color: rgb(170, 170, 170);
}

// ng-select
.ng-dropdown-panel {
    background-color: rgb(248 250 252) !important
}

ng-select .ng-value-container {
    margin-top: 0.5rem;

    input {
        padding: 0.5rem 1rem !important;
    }
}

// ng-select override mouse over effect
ng-select .ng-select-container:hover::after {
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
}

.truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}